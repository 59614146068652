import $ from 'jquery';
import React, {MouseEvent, useState, ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import {humanDayOfWeek} from 'infra/formatters/time';
import moment from 'infra/moment';
import {formatCents} from 'web/helpers/money';
import SigninHelpers from 'web/components/market_layout/components/header/components/signin_helpers';
import useClientSettings from 'web/hooks/useClientSettings';
import assetPath from 'web/helpers/asset_path';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {AppDispatch} from 'web/helpers/redux_client';
import useSessionStorage from 'web/hooks/useSessionStorage';

import {MarketLayoutStore} from '../../store_builder';

const MobileMenu = (): ReactElement => {
  const [expandedCategoryId, setExpandedCategoryId] = useState<string | null>();
  const {tzid} = useClientSettings();
  const dispatch: AppDispatch = useDispatch();
  const {removeKey: removeDismissedSurveyPopin} = useSessionStorage<boolean>(
    'dismissed-survey-popin',
    false,
  );

  const {
    user,
    currentFulfillmentDay,
    categories,
    preorderPeriod,
    upcomingOrdersCount,
    zipCode,
    inferredZipCode,
    closeOnBack,
    productAvailabilityDays,
    redirectOnSelect,
  } = useSelector((s: MarketLayoutStore) => ({
    currentFulfillmentDay: s.currentFulfillmentDay,
    categories: s.categories,
    user: s.user,
    preorderPeriod: s.dayChooserModal.preorderPeriod,
    upcomingOrdersCount: s.upcomingOrders.length,
    zipCode: s.zipCode,
    inferredZipCode: s.inferredZipCode,
    closeOnBack: s.dayChooserModal.closeOnBack,
    productAvailabilityDays: s.dayChooserModal.productAvailabilityDays,
    redirectOnSelect: s.dayChooserModal.redirectOnSelect,
  }));

  const isMasquerading = Boolean(user?.masquerading);
  const isMember = user?.membership?.isMember ?? false;

  const handleCategoryClickHandler = (categoryId: string): void => {
    const newExpandedCategoryId = expandedCategoryId === categoryId ? null : categoryId;
    setExpandedCategoryId(newExpandedCategoryId);
    if (newExpandedCategoryId) {
      setTimeout(() => {
        const mobileMenuDiv = $('.mobile-menu').get(0);
        const $expandedDepartment = $('.mobile-menu .department.expanded');

        if ($expandedDepartment.length > 0 && mobileMenuDiv) {
          mobileMenuDiv.scrollTop += $expandedDepartment.position().top;
        }
      }, 10); // give a chance to render the page before measuring for scrollIntoView
    }
  };

  const handleSignInClicked = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    SigninHelpers.signinAndReturn();
  };

  const handleSignOutClicked = (e: MouseEvent<HTMLAnchorElement>): void => {
    removeDismissedSurveyPopin();

    if (isMasquerading) {
      e.preventDefault();
      SigninHelpers.signoutMasquerade();
    }
  };

  const handleOpenZipCodeModal = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    dispatch(modalActions.openModal({modal: 'ZipCodeModal'}));
  };

  const dayText = `${humanDayOfWeek(currentFulfillmentDay, tzid)}${
    preorderPeriod != null ? ` ${moment(currentFulfillmentDay).format('M/D')} Preorder` : ''
  }`;
  const clientSettings = useClientSettings();
  const enableNewLogos = clientSettings.enableNewLogos.enableMobile;
  const Header = (
    <div className="mobile-user-controls">
      {user ? (
        <div>
          <div
            className={classNames('greetings pull-left', {
              masquerading: isMasquerading,
              member: isMember,
            })}
          >
            {isMember && (
              <img
                alt="Membership badge"
                src={
                  enableNewLogos
                    ? `${assetPath('/img/svg/icons/ge-member-black-new.svg')}?auto=format`
                    : `${assetPath('/img/svg/icons/ge-member-black.svg')}?auto=format`
                }
              />
            )}
            Hi {user.firstName}
            {isMasquerading && ' (MASQUERADING)'}
          </div>
          {user?.availableBalance != null && (
            <div className="available-credit pull-left">({formatCents(user.availableBalance)})</div>
          )}
        </div>
      ) : (
        <a className="login pull-left" href="/signin" onClick={handleSignInClicked}>
          Sign In / Sign Up
        </a>
      )}
    </div>
  );

  const AccountMenu = (
    <div>
      <div className="section-header">
        <h3>Account</h3>
      </div>
      <div className="department">
        <a className="row orders" href="/account/orders">
          Your Orders
          <button className="reveal" type="button">
            {upcomingOrdersCount > 0 && (
              <div className="upcoming-orders-badge">{upcomingOrdersCount}</div>
            )}
          </button>
        </a>
        <a className="row favorites" href="/favorites">
          Your Favorites
        </a>
        <a className="row account" href="/account">
          Your Account
        </a>
        <a className="row invite-friends" href="/refer-a-friend">
          Invite Friends
        </a>
        <a className="row membership" href="/join-membership">
          Join Membership
        </a>
        <a className="row logout" href="/logout" onClick={handleSignOutClicked}>
          Sign Out
        </a>
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        'mobile-menu': true,
        'nav-menu': true,
      })}
    >
      <div className="category-menu">
        {Header}
        <div className="context">
          <a
            href="#"
            className="row link-weighted"
            onClick={() =>
              dispatch(
                modalActions.openDayChooserModal({
                  preorderPeriod,
                  closeOnBack,
                  productAvailabilityDays,
                  redirectOnSelect,
                }),
              )
            }
          >
            <span className="icon icon-truck-outline" /> {dayText}
          </a>
          <a href="#" className="row link-weighted" onClick={handleOpenZipCodeModal}>
            <span className="icon icon-pin" /> {zipCode ?? inferredZipCode}
          </a>
        </div>
        <div className="department">
          <a className="row" href="/reorder">
            Reorder
          </a>
        </div>
        <div className="section-header">
          <h3>Market</h3>
        </div>
        {categories.map((category) => {
          return (
            !category.hidden && ( // Hide thanksgiving url
              <div
                key={category.id}
                className={classNames('department', {
                  expanded: expandedCategoryId === category.id,
                })}
              >
                <div className="row" onClick={() => handleCategoryClickHandler(category.id)}>
                  <span>{category.name}</span>
                  <button className="reveal" type="button">
                    <i className="icon icon-chevron" />
                  </button>
                </div>
                <div className="category-list">
                  {category.subcategories.map((subcategory) => (
                    <a key={subcategory.id} className="subcategory-row" href={subcategory.url}>
                      {subcategory.name}
                    </a>
                  ))}
                </div>
              </div>
            )
          );
        })}
        {user && AccountMenu}
      </div>
    </div>
  );
};

export default MobileMenu;
