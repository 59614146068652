/* eslint-disable unicorn/filename-case */
import {useState} from 'react';

interface SessionStorage<T> {
  storedValue: T;
  setValue: (value: T | ((previousValue: T) => T)) => void;
  removeKey: () => void;
}

const useSessionStorage = <T,>(key: string, initialValue: T): SessionStorage<T> => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') return initialValue;
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((previousValue: T) => T)): void => {
    if (typeof window === 'undefined') return;
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  const removeKey = (): void => {
    try {
      setStoredValue(undefined);
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  };
  return {storedValue, setValue, removeKey};
};

export default useSessionStorage;
